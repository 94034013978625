import Vue from 'vue';
import Router from 'vue-router';

import { auth } from './firebase';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/login/Login.vue'),
    },
    {
      path: '/',
      name: 'index',
      meta: {
        requiresAuth: true,
      },
      component: () => import('./views/Index.vue'),
      redirect: 'calendar',
      children: [
        {
          path: 'availability',
          name: 'availability',
          component: () => import('./views/calendar/Availability.vue'),
        },
        {
          path: 'calendar',
          name: 'calendar',
          component: () => import('./views/calendar/Calendar.vue'),
        },
        {
          path: 'reservations',
          name: 'reservations',
          component: () => import('./views/reservations/Reservations.vue'),
        },
        {
          path: 'bookings',
          name: 'bookings',
          component: () => import('./views/bookings/Bookings.vue'),
        },
        {
          path: 'weights',
          name: 'weights',
          component: () => import('./views/weights/Weights.vue'),
        },
        {
          path: 'customers',
          name: 'customers',
          component: () => import('./views/customers/Customers.vue'),
        },
        {
          path: 'teams',
          name: 'teams',
          component: () => import('./views/teams/Teams.vue'),
        },
        {
          path: 'users',
          name: 'users',
          meta: {
            requiresAdmin: true,
          },
          component: () => import('./views/users/Users.vue'),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

  if (requiresAuth && !currentUser) {
    return next({ name: 'login' });
  }

  if (requiresAdmin) {
    currentUser.getIdTokenResult().then((idTokenResult) => {
      if (idTokenResult.claims.role !== 'admin') {
        next({ name: 'index' });
      } else {
        next();
      }
    });
  } else {
    next();
  }
});

export default router;
