import { format } from 'date-fns';

export default {
  role: (role) => {
    const roles = { admin: 'Administrator', user: 'Benutzer', guest: 'Gast' };

    return roles[role];
  },
  date: (date, formatStr) => {
    if (!date) {
      return '';
    }
    return format(new Date(date), formatStr);
  },
};
